  .lantern-highlight-spiderads-logo *[js-highlight-area="spiderads-logo"],
  .lantern-highlight-spiderads-call-to-action *[js-highlight-area="spiderads-call-to-action"],
  .lantern-highlight-spiderads-banner *[js-highlight-area="spiderads-banner"] {
    outline: 2px dashed var(--brand-friendly);
    outline-offset: 5px;
  }

  .lantern-highlight-spiderads-item-image *[js-highlight-area="spiderads-item-image"],
  .lantern-highlight-spiderads-carousel-box *[js-highlight-area="spiderads-carousel-box"] {
    outline: 2px dashed var(--brand-friendly);
    outline-offset: -5px;
    transition: 500ms all;

    &::before,
    &::after {
      box-sizing: inherit;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .lantern-highlight-spiderads-item-title *[js-highlight-area="spiderads-item-title"],
  .lantern-highlight-spiderads-item-description *[js-highlight-area="spiderads-item-description"],
  .lantern-highlight-spiderads-item-subtitle *[js-highlight-area="spiderads-item-subtitle"],
  .lantern-highlight-spiderads-item-price *[js-highlight-area="spiderads-item-price"],
  .lantern-highlight-spiderads-nav *[js-highlight-area="spiderads-nav"] {
    outline: 2px dashed var(--brand-friendly);
  }

  .lantern-highlight-spiderads-title *[js-highlight-area="spiderads-title"],
  .lantern-highlight-spiderads-description *[js-highlight-area="spiderads-description"],
  .lantern-highlight-spiderads-button *[js-highlight-area="spiderads-button"] {
    outline: 2px dashed var(--brand-friendly);
    outline-offset: 3px;
  }
