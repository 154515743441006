
body {
  color: var(--brand-trust);
  background-color: var(--brand-simplicitly);
  font-size: 15px;
  font-family: "Inter", Arial !important;
}

html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

a,
a:active,
a:visited {
  text-decoration: none;
}

.everyTwo:nth-of-type(odd) {
  background: #fff;
}

.everyTwo:nth-of-type(even) {
  background: #f8f9fb;
}

table {
  border-collapse: collapse;
  margin: 8px 0;
  width: 100%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 4em;
  font-size: 14px;
  position: relative;
}

thead {
  background: #f8f9fb;
}

th {
  font-weight: 600;
  padding: 1em;
  text-align: start;
  position: relative;
}

td {
  padding: 1em 0.2em;
  text-align: start;
}

.Mui-disabled {
  cursor: not-allowed !important;
}

.MuiAutocomplete-popper ul{
  padding: 0 !important;
}